var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.dialogDigitalCertificate,
        callback: function ($$v) {
          _vm.dialogDigitalCertificate = $$v
        },
        expression: "dialogDigitalCertificate",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Vincular certificado digital ")]),
          _c(
            "v-card-text",
            [
              _c("p", { staticClass: "pb-5" }, [
                _vm._v(
                  "No momento, não há certificado digital associado no sistema financeiro para a empresa recentemente adicionada. Por favor, escolha um certificado digital para vincular essas informações à Agnes. Em caso de alterações no certificado, você receberá notificações por e-mail."
                ),
              ]),
              _c("v-autocomplete", {
                attrs: {
                  loading: _vm.isLoading,
                  "search-input": _vm.search,
                  items: _vm.certificates,
                  "hide-no-data": "",
                  dense: "",
                  "append-icon": "mdi-chevron-down",
                  "item-text": "name",
                  "item-value": (item) => item,
                  label: "Certificado digital",
                  outlined: "",
                  "persistent-placeholder": "",
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.search = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        item.name.length > 44
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  bottom: "",
                                  "max-width": "250",
                                  "max-height": "56",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              _vm._b({}, "span", attrs, false),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.start_and_end(
                                                      item.name,
                                                      44
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v(_vm._s(item.name))])]
                            )
                          : _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.start_and_end(item.name, 44))
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.certificateSelected,
                  callback: function ($$v) {
                    _vm.certificateSelected = $$v
                  },
                  expression: "certificateSelected",
                },
              }),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" Cancelar ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.associateCertificate()
                    },
                  },
                },
                [_vm._v(" Salvar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }