import { render, staticRenderFns } from "./ModalAssociateCertificateEdit.vue?vue&type=template&id=ec6838c6&scoped=true"
import script from "./ModalAssociateCertificateEdit.vue?vue&type=script&lang=js"
export * from "./ModalAssociateCertificateEdit.vue?vue&type=script&lang=js"
import style0 from "./ModalAssociateCertificateEdit.vue?vue&type=style&index=0&id=ec6838c6&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec6838c6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ec6838c6')) {
      api.createRecord('ec6838c6', component.options)
    } else {
      api.reload('ec6838c6', component.options)
    }
    module.hot.accept("./ModalAssociateCertificateEdit.vue?vue&type=template&id=ec6838c6&scoped=true", function () {
      api.rerender('ec6838c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/cadastros-gerenciais/components/ModalAssociateCertificateEdit.vue"
export default component.exports