var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Empresas"),
      ]),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "backgroundSecond" } },
                [
                  _c("v-spacer"),
                  _c("ModalImportarCNPJ", {
                    on: { trigger: _vm.openModalAssociateCertificate },
                  }),
                  _c("ModalAssociateCertificate", {
                    ref: "modalAssociateCertificate",
                    attrs: { dataCompany: _vm.dataCompany },
                    on: { trigger: _vm.fetch },
                  }),
                  _c("ModalAssociateCertificateEdit", {
                    ref: "modalAssociateCertificateEdit",
                    attrs: { dataEstablishment: _vm.dataEstablishment },
                    on: { trigger: _vm.fetch },
                  }),
                  _c("ModalAssociateCertificateHistoric", {
                    ref: "modalAssociateCertificateHistoric",
                    attrs: { dataHistoric: _vm.dataHistoric },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { id: "companies-container" } },
            [
              _c("div", { attrs: { id: "scrollDuplicate" } }, [
                _c("div", { attrs: { id: "dummy" } }),
              ]),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.empresas,
                  "server-items-length": _vm.totalCompanies,
                  loading: _vm.loading,
                  options: _vm.options,
                  "footer-props": {
                    "items-per-page-options": [10, 25, 50],
                    itemsPerPageText: `Empresas por página: `,
                  },
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "footer.page-text",
                    fn: function (items) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(items.pageStart) +
                            " - " +
                            _vm._s(items.pageStop) +
                            " de " +
                            _vm._s(_vm.totalCompanies) +
                            " empresas "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.matriz",
                    fn: function ({ item }) {
                      return [
                        _vm._v(" " + _vm._s(_vm._f("cnpj")(item.matriz)) + " "),
                      ]
                    },
                  },
                  {
                    key: "item.certificate",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "span",
                                        _vm._g(
                                          _vm._b({}, "span", attrs, false),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.start_and_end(
                                                item.certificate,
                                                39
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v(_vm._s(item.certificate))])]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.cnae",
                    fn: function ({ item }) {
                      return [
                        _vm._v(" " + _vm._s(_vm._f("cnae")(item.cnae)) + " "),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                disabled: item.btnEditDisabled,
                                                icon: "",
                                                id: "idBtnEdit",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openModalAssociateCertificateEdit(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "success",
                                              },
                                            },
                                            [_vm._v("mdi-pencil-outline")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Editar certificado")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                icon: "",
                                                disabled: !item.configId,
                                                id: "idBtnUpdate",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openModalAssociateCertificateHistoric(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "success",
                                                id: "idBtnUpdate",
                                              },
                                            },
                                            [_vm._v("mdi-update")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Histórico do certificado")])]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }