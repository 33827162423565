var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { rounded: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.getGrupos()
                        },
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" Importar ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialogGrupo,
        callback: function ($$v) {
          _vm.dialogGrupo = $$v
        },
        expression: "dialogGrupo",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Adicionar dados por cnpj ")]),
          _c(
            "v-card-text",
            { staticClass: "pa-5" },
            [
              _c("v-autocomplete", {
                staticClass: "auto-complete-class static",
                attrs: {
                  loading: _vm.isLoading,
                  "search-input": _vm.search,
                  items: _vm.grupos,
                  "hide-no-data": "",
                  dense: "",
                  "append-icon": "mdi-chevron-down",
                  "item-text": "name",
                  "item-value": "id",
                  label: "Selecionar grupo existente",
                  outlined: "",
                  "persistent-placeholder": "",
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.search = $event
                  },
                  mouseover: function ($event) {
                    return _vm.showGroupName()
                  },
                  mouseleave: function ($event) {
                    return _vm.closeGroupName()
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.name",
                    fn: function ({ item }) {
                      return [_vm._v(" " + _vm._s(item) + " ")]
                    },
                  },
                ]),
                model: {
                  value: _vm.grupoSelecionado,
                  callback: function ($$v) {
                    _vm.grupoSelecionado = $$v
                  },
                  expression: "grupoSelecionado",
                },
              }),
              _c("v-text-field", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "##.###.###",
                    expression: "'##.###.###'",
                  },
                ],
                attrs: {
                  label: "Digite o CNPJ raiz",
                  dense: "",
                  rounded: "",
                  outlined: "",
                  "persistent-placeholder": "",
                  color: _vm.inputColor,
                },
                model: {
                  value: _vm.cnpjInserido,
                  callback: function ($$v) {
                    _vm.cnpjInserido = $$v
                  },
                  expression: "cnpjInserido",
                },
              }),
              _c("div", { attrs: { id: "cnpj-hint-div" } }, [
                _c("p", { staticClass: "v-application" }, [
                  _vm._v(_vm._s(_vm.hintMessage)),
                ]),
              ]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogGrupo = false
                    },
                  },
                },
                [_vm._v(" Cancelar ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", disabled: _vm.cnpjInserido.length < 10 },
                  on: {
                    click: function ($event) {
                      return _vm.addGroup()
                    },
                  },
                },
                [_vm._v(" Salvar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }