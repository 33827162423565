<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Empresas</h2>

    <v-card>
      <v-card-title>
        <v-toolbar flat color="backgroundSecond">
          <v-spacer></v-spacer>
          <ModalImportarCNPJ @trigger="openModalAssociateCertificate" />
          <ModalAssociateCertificate @trigger="fetch" ref="modalAssociateCertificate" :dataCompany="dataCompany" />
          <ModalAssociateCertificateEdit
            @trigger="fetch"
            ref="modalAssociateCertificateEdit"
            :dataEstablishment="dataEstablishment"
          />
          <ModalAssociateCertificateHistoric ref="modalAssociateCertificateHistoric" :dataHistoric="dataHistoric" />
        </v-toolbar>
      </v-card-title>
      <div id="companies-container">
        <div id="scrollDuplicate">
          <div id="dummy"></div>
        </div>
        <v-data-table
          :headers="headers"
          :items="empresas"
          :server-items-length="totalCompanies"
          class="elevation-1"
          :loading="loading"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
            itemsPerPageText: `Empresas por página: `,
          }"
        >
          <template v-slot:footer.page-text="items">
            {{  items.pageStart }} - {{ items.pageStop }} de {{ totalCompanies }}  empresas 
          </template>
          <template v-slot:item.matriz="{ item }">
            {{ item.matriz | cnpj }}
          </template>
          <template v-slot:item.certificate="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ start_and_end(item.certificate, 39) }}</span>
              </template>
              <span>{{ item.certificate }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.cnae="{ item }">
            {{ item.cnae | cnae }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="item.btnEditDisabled" icon v-bind="attrs" v-on="on" id="idBtnEdit" @click="openModalAssociateCertificateEdit(item)">
                  <v-icon small color="success">mdi-pencil-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar certificado</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="!item.configId"
                  v-bind="attrs"
                  v-on="on"
                  id="idBtnUpdate"
                  @click="openModalAssociateCertificateHistoric(item)"
                >
                  <v-icon small color="success" id="idBtnUpdate">mdi-update</v-icon>
                </v-btn>
              </template>
              <span>Histórico do certificado</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-main>
</template>
<script>
import AdminServices from '@/services/adminServices.js';

import ModalImportarCNPJ from './components/ModalImportarCNPJ.vue';
import ModalAssociateCertificate from './components/ModalAssociateCertificate.vue';
import ModalAssociateCertificateEdit from './components/ModalAssociateCertificateEdit.vue';
import ModalAssociateCertificateHistoric from './components/ModalAssociateCertificateHistoric.vue';

export default {
  name: 'Empresas',

  components: {
    ModalImportarCNPJ,
    ModalAssociateCertificate,
    ModalAssociateCertificateEdit,
    ModalAssociateCertificateHistoric,
  },
  updated() {
    this.customize();
  },
  mounted() {
    this.updateScrollers();
    window.addEventListener('resize', this.updateScrollers);

    const linkScroller = (a, b) => {
      a.addEventListener('scroll', (e) => {
        b.scrollLeft = e.target.scrollLeft;
      });
    };
    const _a = this.$el.querySelector('#scrollDuplicate');
    const _b = this.$el.querySelector('.v-data-table__wrapper');
    linkScroller(_a, _b);
    linkScroller(_b, _a);
  },
  data() {
    return {
      dialogData: null,
      dataCompany: {},
      dataEstablishment: {},
      dataHistoric: {},
      totalCompanies: 0,
      options: {},
      loading: true,
      headers: [
        { text: 'Razão social', align: 'start', value: 'socialName', width: '450px' },
        { text: 'CNPJ matriz', value: 'matriz', width: '150px', sortable: false },
        { text: 'Certificado digital', value: 'certificate', width: '350px', sortable: false },
        { text: 'CNAE', value: 'cnae', width: '84px', sortable: false },
        { text: 'UF', align: 'start', value: 'uf', width: '40px', sortable: false },
        { text: 'Cidade', align: 'start', value: 'city', width: '140px', sortable: false },
        { text: 'Ações', value: 'actions', width: '105px', sortable: false },
      ],

      empresas: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.fetch();
      },
    },
  },

  methods: {
    openModalAssociateCertificateHistoric(item) {
      this.dataHistoric = {};
      this.dataHistoric = item;
      setTimeout(() => this.$refs.modalAssociateCertificateHistoric.$emit('open'), 500);
    },
    openModalAssociateCertificateEdit(item) {
      item.btnEditDisabled = true;
      this.dataEstablishment = {};
      this.dataEstablishment = item;
      setTimeout(() => {
        this.$refs.modalAssociateCertificateEdit.$emit('open');
      }, 500);
    },
    openModalAssociateCertificate(dataCompany) {
      this.dataCompany = dataCompany;
      setTimeout(() => this.$refs.modalAssociateCertificate.$emit('open'), 500);
    },
    async fetch() {
      const params = this.pegarParamsParaRequest();
      try {
        const response = await AdminServices.company.getByCertificate(params);
        this.empresas = response.data.content;
        this.empresas.forEach(element => {
          element.btnEditDisabled = false;
        });
        this.totalCompanies = response.data.totalElements;

        this.loading = false;
      } catch (e) {
        console.log(e)
        localStorage.removeItem('access_Token');
        localStorage.removeItem('refresh_Token');
        this.$router.push('/');
      }
    },

    pegarParamsParaRequest() {
      let params = {};

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      if (page) {
        params['page'] = page - 1;
      }

      if (itemsPerPage) {
        params['size'] = itemsPerPage;
      }
      if (sortBy && sortBy.length === 1) {
        params['sort'] = sortBy[0];
        params['sort'] += ',' + (sortDesc[0] === true ? 'desc' : 'asc');
      }

      return params;
    },
    customize() {
      let tableLines = document.querySelectorAll('#companies-container tbody tr');
      if (tableLines && tableLines.length > 1) {
        for (let i = 0; i < tableLines.length; i++) {          
          tableLines[i].style.backgroundColor = '#FFFFFF';
          if (i % 2) {
            tableLines[i].style.backgroundColor = '#F7F7FD';
          }
        }
      }
    },
    updateScrollers() {
      const dummy = this.$el.querySelector('#dummy');
      const _b = this.$el.querySelector('.v-data-table__wrapper');
      dummy.style.width = _b.scrollWidth + 'px';
    },
    start_and_end(str, lengthStr) {
      if (str != undefined) {
        if (str.length > lengthStr) {
          return str.substr(0, lengthStr) + '...';
        }
        return str;
      }
    },
  },
};
</script>
<style scoped>
::v-deep #scrollDuplicate,
::v-deep .v-data-table__wrapper {
  overflow-x: auto !important;
}
::v-deep .v-data-table__wrapper table {
  max-height: 330px !important;
}

::v-deep #scrollDuplicate > div {
  height: 1px !important;
}
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
}

::v-deep .v-data-table-header tr th {
  line-height: 18.62px;
  letter-spacing: 1%;
  height: 51px;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1px;
  cursor: default;
}

::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}
#companies-container >>> tr .text-start {
  border: none;
}
::v-deep .v-data-table tr td.text-start {
  cursor: default !important;
}

::v-deep .v-input__slot::before {
  border: none !important;
}
::v-deep .v-input__slot::after {
  border: none !important;
}
::v-deep .v-data-footer__select {
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}

::v-deep .v-data-footer__select .v-select {
  margin: 16px 64px 16px 8px !important;
}
::v-deep .v-data-footer__pagination {
  margin: 0px 64px 0px 0px;
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}
::v-deep .v-data-footer {
  padding-left: 16px;
  padding-right: 16px;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: #e7e7fa;
  color: #8686a2 !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 0;
  padding: 0;
}
::v-deep .v-data-footer__icons-after .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-left: 0px !important;
  margin-right: 0;
  padding: 0;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 8px !important;
  padding: 0;
}
::v-deep .v-data-footer .v-input__slot {
  background: #e3ffee !important;
  color: var(--v-primary-base);
}
::v-deep .v-data-footer .v-data-footer__select .v-select {
  background: #ffffff !important;
  color: var(--v-primary-base);
  margin-top: 16px;
  margin-bottom: 0px;
}

::v-deep .v-data-footer__icons-after .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
  padding: 0 !important;
}

::v-deep .v-data-footer__icons-after .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
  padding: 0 !important;
}

::v-deep .v-data-footer__icons-before:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  color: var(--v-primary-base);
  border-radius: 100% !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1 !important;
  padding: 0 !important;
}

::v-deep .v-select__slot .v-input__append-inner {
  padding-top: 7px;
}
::v-deep .v-select__slot .v-select__selections .v-select__selection {
  font-family: Roboto !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #8686a2 !important;
}

::v-deep .v-data-footer .v-select__slot {
  background-color: #e7e7fa;
  height: 48px;
  width: 83px;
  border-radius: 4px;
  border: 1px solid #e7e7fa;
  padding-left: 15px;
  padding-right: 10px;
}

::v-deep .v-data-footer__icons-after:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  opacity: 1 !important;
  transition: none !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn--disabled {
  padding: 0 !important;
  background-color: #e7e7fa !important;
  border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-after .v-btn--disabled {
  background-color: #e7e7fa !important;
  padding: 0 !important;
  border-radius: 100% !important;
}
</style>
