<template>
    <v-dialog v-model="dialogDigitalCertificate" width="500" persistent>
        <v-card>
          <v-card-title> Vincular certificado digital </v-card-title>
    
          <v-card-text>
            <p class="pb-5">No momento, não há certificado digital associado no sistema financeiro para a empresa recentemente adicionada. Por favor, escolha um certificado digital para vincular essas informações à Agnes. Em caso de alterações no certificado, você receberá notificações por e-mail.</p>
          <v-autocomplete
                    v-model="certificateSelected"
                    :loading="isLoading"
                    :search-input.sync="search"
                    :items="certificates"
                    hide-no-data
                    dense
                    append-icon="mdi-chevron-down"
                    item-text="name"
                    :item-value="(item) => item"
                    label="Certificado digital"
                    outlined
                    persistent-placeholder
                  >
                    <template v-slot:item="{ item }">
                      <v-tooltip bottom v-if="item.name.length > 44" max-width="250" max-height="56">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ start_and_end(item.name, 44) }}
                          </span>
                        </template>
                        <span>{{ item.name }}</span>
                      </v-tooltip>
                      <span v-else> {{ start_and_end(item.name, 44) }}</span>
                    </template>
            </v-autocomplete>
          </v-card-text>
    
          <v-divider />
    
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="close()"> Cancelar </v-btn>
            <v-btn text color="success" @click="associateCertificate()"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </template>  
<script> 
import AdminServices from '@/services/adminServices.js';
import { eventBus } from '../../../../main.js';

export default {
    name: 'ModalAssociateCertificate',
    props: ['dataCompany'],
  
    data: () => ({
      dialogDigitalCertificate: false, 
      certificateSelected: null,
      certificates: [],
      isLoading: false,
      search: null,
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    }),

    created() {
      this.init();
    },
    methods: {   
      init() {
        this.$on('open', this.open);
      },
      async associateCertificate(){
        if (this.certificateSelected) {
          let data = {
            cnpj: this.dataCompany.cnpj,
            name: "current_certificate",
            config: {
              name: this.certificateSelected.name,
            },
          };
          try {
            await AdminServices.digitalCertificate.associate(data);
            this.showToast(`Empresa adicionada e vinculada com sucesso!`, 'success', 78);
          } catch (e) {
            this.showToast(`Erro ao vincular certificado!`, 'error', 78);
          }
        } else {
          this.showToast(`Empresa adicionada com sucesso!`, 'success', 78);
        }     
        this.$emit('trigger');
        this.dialogDigitalCertificate = false;   
      },
      async open() {
        this.dialogDigitalCertificate = true;
        this.certificates = this.getDigitalCertificate();
      },
      close(){
        this.$emit('trigger');
        this.dialogDigitalCertificate = false;
      },
      async getDigitalCertificate(){        
        const response = await AdminServices.digitalCertificate.get();
        if (response.data) {
          this.certificates = response.data;
        }
      },
      start_and_end(str, lengthStr) {
        if (str.length > lengthStr) {
          return str.substr(0, lengthStr) + '...';
        }
        return str;
      },    
      showToast(message, type, topToast) {
        this.toast.show = true;
        this.toast.type = type;
        this.toast.message = message;
        this.toast.topToast = topToast;
        eventBus.$emit('openToast', this.toast);
      },
    }

};
</script>
<style scoped>
</style>
  
