var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.dialogDigitalCertificateHistoric,
        callback: function ($$v) {
          _vm.dialogDigitalCertificateHistoric = $$v
        },
        expression: "dialogDigitalCertificateHistoric",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pt-1 pb-1 pl-1 pr-3" },
            [
              _c(
                "v-col",
                {
                  staticStyle: { "font-size": "20px", "font-weight": "bold" },
                  attrs: { cols: "11" },
                },
                [_vm._v(" Histórico do certificado digital ")]
              ),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "btnCloseX", icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.historics, function (item, index) {
            return _c("v-card-text", { key: index, staticClass: "pt-3" }, [
              _c(
                "div",
                {
                  staticClass: "row rounded mt-1",
                  staticStyle: { "background-color": "#fff" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "p-5" },
                    [
                      _c(
                        "v-subheader",
                        {
                          staticClass: "pl-3 pt-5 pb-5",
                          staticStyle: { "max-height": "24px" },
                        },
                        [_vm._v("Data/hora")]
                      ),
                      _c(
                        "v-subheader",
                        {
                          staticClass: "pl-3 pt-0 pb-5",
                          staticStyle: { "max-height": "24px" },
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.formatarData(item.updatedAt))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", {
                    staticClass: "mt-3 mb-3",
                    attrs: { vertical: "" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "p-5",
                      staticStyle: { "min-width": "200px" },
                    },
                    [
                      _c(
                        "v-subheader",
                        {
                          staticClass: "pl-3 pt-5 pb-5",
                          staticStyle: { "max-height": "24px" },
                        },
                        [_vm._v("Alterado por")]
                      ),
                      _c(
                        "v-subheader",
                        {
                          staticClass: "pl-3 pt-0 pb-5",
                          staticStyle: { "max-height": "24px" },
                        },
                        [_c("strong", [_vm._v(_vm._s(item.fullUserName))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "p-5" },
                    [
                      _c(
                        "v-subheader",
                        {
                          staticClass: "pl-3 pt-5 pb-3",
                          staticStyle: { "max-height": "24px" },
                        },
                        [_vm._v("Certificado digital")]
                      ),
                      _c(
                        "v-subheader",
                        {
                          staticClass: "pl-3 pt-3 pb-5",
                          staticStyle: { "text-align": "left" },
                        },
                        [_c("strong", [_vm._v(_vm._s(item.certificate))])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          }),
          _c("v-card-text", { staticClass: "pt-5" }, [
            _c("strong", [
              _vm._v(
                "Você visualizou todo o histórico de certificado digital deste estabelecimento."
              ),
            ]),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticStyle: { height: "48px" },
                  attrs: { color: "success", id: "btnSalvar" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" Fechar ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }