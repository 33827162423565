<template>
  <v-dialog v-model="dialogGrupo" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded v-bind="attrs" color="primary" @click="getGrupos()" v-on="on"> Importar </v-btn>
    </template>

    <v-card>
      <v-card-title> Adicionar dados por cnpj </v-card-title>

      <v-card-text class="pa-5">
        <v-autocomplete
          class="auto-complete-class static"
          v-model="grupoSelecionado"
          :loading="isLoading"
          :search-input.sync="search"
          :items="grupos"
          hide-no-data
          dense
          append-icon="mdi-chevron-down"
          item-text="name"
          item-value="id"
          label="Selecionar grupo existente"
          outlined
          persistent-placeholder
          v-on:mouseover="showGroupName()"
          v-on:mouseleave="closeGroupName()"
        >
          <template v-slot:item.name="{ item }">
            {{ item }}
          </template>
        </v-autocomplete>

        <v-text-field
          v-mask="'##.###.###'"
          v-model="cnpjInserido"
          label="Digite o CNPJ raiz"
          dense
          rounded
          outlined
          persistent-placeholder
          :color="inputColor"
        >
        </v-text-field>
        <div id="cnpj-hint-div">
          <p class="v-application">{{ hintMessage }}</p>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="dialogGrupo = false"> Cancelar </v-btn>
        <v-btn text :disabled="cnpjInserido.length < 10" @click="addGroup()"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AdminServices from '@/services/adminServices.js';

export default {
  name: 'ModalImportarCNPJ',

  data: () => ({
    dialogGrupo: false,
    cnpjInserido: '',
    grupoSelecionado: [],
    grupos: [],
    isLoading: false,
    search: null,
    autoselectMenu: false,
    spanName: '',
    inputColor: '',
    hintMessage: '',
  }),

  methods: {
    toggle() {
      this.autoselectMenu = !this.autoselectMenu;
    },

    async addGroup() {
      this.clearRequiredFieldsMsg();
      let groupId = [];

      if (this.grupoSelecionado && this.grupoSelecionado.length > 0) {
        groupId = this.grupoSelecionado;
      } else {
        groupId = null;
      }

      let data = {
        id: groupId,
        cnpj: this.cnpjInserido.substr(0, 10).replaceAll('.', ''),
      };

      if (!data.cnpj) {
        this.showRequiredFieldsMsg('Digite o CNPJ');
        return;
      }

      try {
        await AdminServices.wizard.get(data);
        this.$emit('trigger', data);
      } catch (e) {
        let message = e.response.data.message;
        if (message.includes('company_already_saved')) {
          this.showRequiredFieldsMsg('CNPJ já cadastrado');
          return;
        }
        localStorage.removeItem('access_Token');
        localStorage.removeItem('refresh_Token');
        this.$router.push('/');
      }
      this.dialogGrupo = false;
    },
    showRequiredFieldsMsg(message) {
      if (this.hintMessage == '') {
        this.hintMessage = message;
        this.inputColor = '#962d33';
        document.querySelector('#cnpj-hint-div').classList.add('v-messages__message');
      }
    },
    clearRequiredFieldsMsg() {
      if (this.hintMessage != '') {
        this.hintMessage = '';
        this.inputColor = 'primary';
        document.querySelector('#cnpj-hint-div').classList.remove('v-messages__message');
      }
    },
    async getGrupos() {
      this.clearRequiredFieldsMsg();

      const params = { page: 0, size: 100 };
      try {
        let response = await AdminServices.group.get(params);
        let keys = [];
        let listado = [];
        let gruposResp = response.data.content;

        Object.entries(gruposResp).forEach(([key, entries]) => {
          keys.push(key);
          listado.push({
            name: entries.name,
            id: entries.id,
          });
        });

        this.grupos = listado;
        this.myloadingvariable = false;
      } catch (e) {
        localStorage.removeItem('access_Token');
        localStorage.removeItem('refresh_Token');
        this.$router.push('/');
      }
    },
    showGroupName() {
      this.spanName = document.querySelector('.auto-complete-class input').value;
      if (this.spanName != '') {
        document.querySelector('#item-span').style.display = 'block';
      }
    },
    async closeGroupName() {
      let sleepTime = 390;
      await new Promise((r) => setTimeout(r, sleepTime));
      document.querySelector('#item-span').style.display = 'none';
      this.spanName = '';
    },
  },
};
</script>
<style scoped>
.auto-complete-class >>> .v-select__selections {
  max-height: 40px;
  white-space: nowrap;
  display: block;
}

.auto-complete-class >>> .v-select__selections::-webkit-scrollbar {
  display: none;
}
#item-span {
  left: 490px;
  max-width: 500px;
  top: 99px;
  z-index: 204;
  color: white;
  opacity: 87%;
  border-radius: 9px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base) !important;
  display: none;
  position: fixed;
  margin-top: 14%;
}
#cnpj-hint-div {
  font-size: 12px;
  margin-top: -18px;
  bottom: 3px;
  color: #962d33;
}
</style>
