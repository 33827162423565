<template>
    <v-dialog v-model="dialogDigitalCertificateEdit" width="500" persistent>
        <v-card>
          <v-card-title class="titleStyle pl-4 pb-0 pt-0" style="max-height: 63px !important">
            <v-col cols="11" class="pl-0"> Editar certificado vinculado </v-col>
            <v-col cols="1">
              <v-btn id="btnCloseX" icon dark @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-card-title>    
          <v-divider class="mt-1 mb-5"></v-divider>
          <v-card-text class="pl-4 pr-4 pb-2">
            <v-autocomplete
                v-model="establishmentSelected"
                :items="establishments"                
                :item-value="(item) => item"
                item-text="item"
                hide-no-data
                dense
                append-icon="mdi-chevron-down"
                label="Estabelecimento"
                persistent-placeholder
                disabled
                style="
                  background-color: #e7e7fa !important;
                  height: 48px;
                  margin-bottom: 16px;
                  border: 0px solid #CCCCCC !important;
                  border-radius: 4px;
                  padding: 16px;"
                id="fieldEstablishment"
            >
              <template v-slot:selection="{ index }">
                  <span v-if="index === 0">
                    {{ establishmentSelected | cnpj }}
                  </span>
                </template>
            </v-autocomplete>
            <v-autocomplete
                    v-model="certificateSelected"
                    :loading="isLoading"
                    :search-input.sync="search"
                    :items="certificates"
                    hide-no-data
                    dense
                    append-icon="mdi-chevron-down"
                    item-text="name"
                    :item-value="(item) => item"
                    label="Certificado digital"
                    outlined
                    persistent-placeholder
                    style="height: 48px;"
                  >
                    <template v-slot:item="{ item }">
                      <v-tooltip bottom v-if="item.name.length > 44" max-width="250" max-height="56">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ start_and_end(item.name, 44) }}
                          </span>
                        </template>
                        <span>{{ item.name }}</span>
                      </v-tooltip>
                      <span v-else> {{ start_and_end(item.name, 44) }}</span>
                    </template>
            </v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="success" @click="close()"> Cancelar </v-btn>
            <v-btn color="success" style="height: 48px" id="btnSalvar" @click="associateCertificate()"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </template>  
<script> 
import AdminServices from '@/services/adminServices.js';
import { eventBus } from '../../../../main.js';
import { mapState } from 'vuex';

export default {
    name: 'ModalAssociateCertificateEdit',
    props: ['dataEstablishment'],
  
    data: () => ({
      dialogDigitalCertificateEdit: false,
      establishmentSelected: null,
      certificateSelected: null,
      certificates: [],
      establishments: [],
      isLoading: false,
      search: null,
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    }),
    computed: {
      ...mapState('user', {
        user: (state) => state.user,
      }),
    },
    created() {
      this.init();
    },
    methods: {   
      init() {
        this.$on('open', this.open);
      },
      open() {
        this.establishmentSelected = this.dataEstablishment.matriz;
        this.establishments[0] = this.establishmentSelected;
        this.getCertificates();
      },
      async getCertificates(){        
        const response = await AdminServices.digitalCertificate.get();
        if (response.data) {
          this.certificates = response.data;
          this.certificates.forEach(element => {
            if (element.name === this.dataEstablishment.certificate) {
              this.certificateSelected = element;
            }
          });          
          this.dialogDigitalCertificateEdit = true;
        }
      },
      async associateCertificate(){
        if (this.certificateSelected) {
          let data = {
            cnpj: this.dataEstablishment.matriz.substr(0, 8),
            name: "current_certificate",            
            config: {
              name: this.certificateSelected.name,
              user: this.user.firstName + " " + this.user.lastName,
            },
          };
          
          await AdminServices.digitalCertificate.associate(data);
          this.dialogDigitalCertificateEdit = false;          
          this.$emit('trigger');
          this.showToast(`Certificado digital alterado com sucesso!`, 'success', 78);
        }       
      },
      close() {
        this.certificateSelected = null;
        this.certificates = [];
        this.dialogDigitalCertificateEdit = false;        
        this.$emit('trigger');
      },
      start_and_end(str, lengthStr) {
        if (str.length > lengthStr) {
          return str.substr(0, lengthStr) + '...';
        }
        return str;
      },    
      showToast(message, type, topToast) {
        this.toast.show = true;
        this.toast.type = type;
        this.toast.message = message;
        this.toast.topToast = topToast;
        eventBus.$emit('openToast', this.toast);
      },
    }
};
</script>
<style scoped>
.titleStyle {
  font-size: 20px;
  font-weight: 700 !important;
  line-height: 30px;
  align-content: center;
}
::v-deep .v-text-field .v-input__control .v-input__slot::before{
  border-style: none !important; 
}
.theme--light.v-list{
  background: #E3FFE3 !important; 
}
</style>
  
