<template>
    <v-dialog v-model="dialogDigitalCertificateHistoric" width="500" persistent>
        <v-card>
          <v-card-title class="pt-1 pb-1 pl-1 pr-3">
            <v-col cols="11" style="font-size: 20px; font-weight: bold"> Histórico do certificado digital </v-col>
            <v-col cols="1">
              <v-btn id="btnCloseX" icon dark @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-card-title> 
          <v-card-text class="pt-3" v-for="(item, index) in historics" :key="index">
            <div class="row rounded mt-1" style="background-color: #fff;"  >
                <div class="p-5">
                    <v-subheader style="max-height: 24px" class="pl-3 pt-5 pb-5">Data/hora</v-subheader>
                    <v-subheader style="max-height: 24px" class="pl-3 pt-0 pb-5">
                        <strong>{{formatarData(item.updatedAt)}}</strong>
                    </v-subheader>
                </div>
                <v-divider vertical class="mt-3 mb-3"></v-divider>
                <div class="p-5" style="min-width: 200px;">
                    <v-subheader style="max-height: 24px" class="pl-3 pt-5 pb-5">Alterado por</v-subheader>
                    <v-subheader style="max-height: 24px" class="pl-3 pt-0 pb-5">
                        <strong>{{item.fullUserName}}</strong>
                    </v-subheader>
                </div>
                <div class="p-5">
                    <v-subheader style="max-height: 24px" class="pl-3 pt-5 pb-3">Certificado digital</v-subheader>
                    <v-subheader style="text-align: left;" class="pl-3 pt-3 pb-5">
                        <strong>{{item.certificate}}</strong>
                    </v-subheader>
                </div>
            </div>
          </v-card-text>
          <v-card-text class="pt-5">
            <strong>Você visualizou todo o histórico de certificado digital deste estabelecimento.</strong>
          </v-card-text>
          <v-card-actions>
            <v-spacer /> 
            <v-btn color="success" style="height: 48px" id="btnSalvar" @click="close()"> Fechar </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </template>  
<script>
import AdminServices from '@/services/adminServices.js';
export default {
    name: 'ModalAssociateCertificateHistoric',
    props: ['dataHistoric'],
  
    data: () => ({ 
      dialogDigitalCertificateHistoric: false,
      historics: [],
    }),
    created() {
      this.init();
    },
    methods: {
      init() {
        this.$on('open', this.open);
      },
      open() {
        this.getHistoricCertificate(this.dataHistoric.configId);
      },
      async getHistoricCertificate(id){
        const response = await AdminServices.digitalCertificate.historic(id);
        if (response) {
          this.historics = response.data;
          this.dialogDigitalCertificateHistoric = true;
        }
      },
      formatarData(timestamp) {
        const data = new Date(timestamp);
        const dia = ("0" + data.getDate()).slice(-2);
        const mes = ("0" + (data.getMonth() + 1)).slice(-2);
        const ano = data.getFullYear();
        const horas = ("0" + data.getHours()).slice(-2);
        const minutos = ("0" + data.getMinutes()).slice(-2);
        return `${dia}/${mes}/${ano} - ${horas}:${minutos}`;
      },
      close() {
        this.dialogDigitalCertificateHistoric = false;
      },
    }
};
</script>
<style scoped>
.v-card__text{
  background-color: #e7e7fa;
  text-align: center;
  color: var(--v-primary-base);
}
strong{
    color: #52526B;
}
</style>
  
